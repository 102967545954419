import React from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Typography,
  MenuItem,
  Menu,
  Divider,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import { Link, useLocation } from 'react-router-dom';
import LocalImages from '../../utils/images';
import UniModal from '../Modal';
import { useSelector, useDispatch } from 'react-redux';
import { ReducersModel } from '../../model';
import { logout } from './action';
import { useHistory } from 'react-router';
import Utils from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      height: '94px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      backgroundColor: 'var(--light-blue)',
      color: 'var(--primary)',
    },

    toolbarHeading: {
      height: '35px',
      fontFamily: 'roboto',
      fontWeight: 500,
      fontSize: '30px',
      lineHeight: '35px',
      [theme.breakpoints.down('sm')]: {
        height: '16',
        fontSize: '12px',
        padding: '2px 0',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '22px',
      },
      [theme.breakpoints.down(820)]: {
        fontSize: '17px',
      },
    },
    toolbarHeading1: {
      cursor: 'pointer',
    },
    changePasswordImage: {
      width: '40px',
      height: '40px',
    },
    userMenu: {
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      marginLeft: '5px',
      marginRight: '-20px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-15px',
      },
    },
    modalButton: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '20px 20px',
    },
    headContainer: {
      display: 'flex',
      alignItems: 'center',
      // width: '52%',
    },
    img: {
      margin: '0 10px',
      width: '37px',
      height: '35px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 5px',
        width: '27px',
        height: '22px',
      },
    },
    iconAccount: {
      '& .MuiSvgIcon-root': {
        fill: 'var(--primary)',
      },
    },
    iconAccount1: {
      '& .MuiSvgIcon-root': {
        fill: 'var(--water)',
      },
    },
  })
);

export default function AdminHeader(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isOpenAddModal, isEdit } = useSelector(
    (state: ReducersModel) => state.ListingAddParticipantReducer
  );

  const { isUserEdit } = useSelector(
    (state: ReducersModel) => state.userMenuEditReducer
  );
  // const history = useHistory();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    dispatch({
      type: Utils.ActionName.UPDATE_TRANSACTION_LIST,
      payload: {
        isOpenAddModal: false,
      },
    });
  };

  const handleEditCloseModal = () => {
    dispatch({
      type: Utils.ActionName.UPDATE_TRANSACTION_LIST,
      payload: {
        isEdit: false,
      },
    });
  };

  const body = (
    <div className={classes.modalButton}>
      <Button
        variant="contained"
        style={{ backgroundColor: 'var(--water)', color: 'var(--primary)' }}
        onClick={() => dispatch(logout())}
      >
        Yes
      </Button>
      <Button
        variant="outlined"
        style={{ backgroundColor: '#fff', color: 'var(--water)' }}
        onClick={handleClose}
      >
        No
      </Button>
    </div>
  );

  return (
    <div>
      <div className={classes.toolbar}>
        {pathname === '/listing-component' ? (
          <div className={classes.headContainer}>
            {isOpenAddModal ? (
              <React.Fragment>
                <Typography
                  className={classes.toolbarHeading}
                  onClick={handleCloseModal}
                >
                  Trained People List
                </Typography>{' '}
                <img
                  src={LocalImages.HEAD_ARROW}
                  alt="Head Arrow"
                  className={classes.img}
                />
                <Typography className={classes.toolbarHeading}>
                  Add new
                </Typography>
              </React.Fragment>
            ) : isEdit ? (
              <React.Fragment>
                <Typography
                  variant="h2"
                  className={`${classes.toolbarHeading1} ${classes.toolbarHeading}`}
                  onClick={handleEditCloseModal}
                >
                  Trained People List
                </Typography>{' '}
                <img
                  src={LocalImages.HEAD_ARROW}
                  alt="Head Arrow"
                  className={classes.img}
                />
                <Typography variant="h2" className={classes.toolbarHeading}>
                  Edit
                </Typography>
              </React.Fragment>
            ) : (
              <Typography variant="h2" className={classes.toolbarHeading}>
                Trained People List
              </Typography>
            )}
          </div>
        ) : (
          ''
        )}

        {pathname === '/changePassword' ? (
          <Typography variant="h2" className={classes.toolbarHeading}>
            Change Password
          </Typography>
        ) : (
          ''
        )}

        {pathname === '/news' ? (
          <Typography variant="h2" className={classes.toolbarHeading}>
            News
          </Typography>
        ) : (
          ''
        )}

        {pathname === '/statistics' ? (
          <Typography variant="h2" className={classes.toolbarHeading}>
            Statistics
          </Typography>
        ) : (
          ''
        )}

        {pathname === '/survey' ? (
          <Typography variant="h2" className={classes.toolbarHeading}>
            Survey
          </Typography>
        ) : (
          ''
        )}

        {pathname === '/feedback' ? (
          <Typography variant="h2" className={classes.toolbarHeading}>
            Feedback
          </Typography>
        ) : (
          ''
        )}

        {pathname === '/listing-detail' ? (
          <div className={classes.headContainer}>
            <React.Fragment>
              <Link to="/listing-component">
                <Typography
                  variant="h2"
                  className={`${classes.toolbarHeading1} ${classes.toolbarHeading}`}
                >
                  Trained People List
                </Typography>
              </Link>{' '}
              <img
                src={LocalImages.HEAD_ARROW}
                alt="Head Arrow"
                className={classes.img}
              />
              <Typography variant="h2" className={classes.toolbarHeading}>
                Trained People Detail Page
              </Typography>
            </React.Fragment>
          </div>
        ) : (
          ''
        )}

        {pathname === '/userMenu' ? (
          <div className={classes.headContainer}>
            {isUserEdit ? (
              <React.Fragment>
                <Typography variant="h2" className={classes.toolbarHeading}>
                  Admin Profile
                </Typography>{' '}
                <img
                  src={LocalImages.HEAD_ARROW}
                  alt="Head Arrow"
                  className={classes.img}
                />
                <Typography variant="h2" className={classes.toolbarHeading}>
                  Edit
                </Typography>
              </React.Fragment>
            ) : (
              <Typography variant="h2" className={classes.toolbarHeading}>
                Admin Profile
              </Typography>
            )}
          </div>
        ) : (
          ''
        )}

        <div className={classes.userMenu}>
          <div>
            <Typography style={{ color: 'white', marginLeft: '15px' }}>
              Hello {localStorage.getItem('firstName')}!
            </Typography>
          </div>
          <Button onClick={handleMenuOpen} className={classes.iconAccount}>
            <AccountCircleIcon fontSize="large" />
          </Button>
          <Button onClick={handleOpen}>
            <img src={LocalImages.EXIT_TO_PAGE} alt={'exit to page'} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
            }}
            style={{
              borderRadius: '30px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() =>
                history.push(Utils.constants.routeEndpoints.userMenu)
              }
              className={classes.iconAccount1}
            >
              <AccountCircleIcon fontSize="large" />
              <Typography style={{ marginLeft: '5px' }}>
                Admin Profile
              </Typography>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() =>
                history.push(Utils.constants.routeEndpoints.changePassword)
              }
              className={classes.iconAccount1}
            >
              {/* <img
                  src={LocalImages.CHANGE_PASSWORD}
                  className={classes.changePasswordImage}
                  alt={'change password'}
                /> */}
              <SettingsBackupRestoreIcon fontSize="large" />
              <Typography style={{ marginLeft: '5px' }}>
                Change Password
              </Typography>
            </MenuItem>
          </Menu>
          <UniModal
            open={isOpen}
            heading={'Are you sure you want to exit ?'}
            handleClose={handleClose}
            body={body}
          />
        </div>
      </div>
    </div>
  );
}
