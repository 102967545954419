import React from 'react';
import {
  makeStyles,
  Theme,
  Modal,
  Typography,
} from '@material-ui/core';

const styles = makeStyles((theme: Theme) => ({
  modalContainer: { borderRadius: '10px' },
  modalWrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    outline:'none',
    border:"0px solid white"
  },
  modalCloseButton: {
    position: 'absolute',
    top: '-25%',
    right: '-10%',
  },
  modalHeading: {
    marginTop: '20px',
    textAlign: 'center',
  },
}));

export default function UniModal(props: any) {
  const classes = styles();
  return (
    <div className={classes.modalContainer}>
      <Modal
        open={props.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalWrapper}>
          <Typography className={classes.modalHeading}>
            {props.heading}
          </Typography>
          {props.body}
        </div>
      </Modal>
    </div>
  );
}
