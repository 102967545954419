import axios from 'axios';

import {
  setSession,
  removeSession,
  getAccessToken,
  getUserIdToken,
  setAccessToken,
  manageUserSession,
  // getCurrentLanguage,
  setAuthorizationToken,
  setApiId,
} from './session';

const ApiKey = process.env.REACT_APP_API_KEY;

const d = new Date();
const offsetValue = -1 * d.getTimezoneOffset();
const apiURLBase = `${process.env.REACT_APP_DOMAIN}/v1/admin`;
const $axios = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN}/v1/admin`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    offsetValue: offsetValue,
    ip: '0.0.0.0',
  },
});

// const CancelToken = axios.CancelToken;
const api_error_code = {
  unauthorized: 401,
  accessDenied: 430,
  // sessionExpired: 440,
  sessionExpired: 419,
  validationError: 400,
  emailNotVerified: 403,
};

const api_success_code = {
  postSuccess: 201,
  success: 200,
};

const user_type = {
  SUB_ADMIN: 2,
  ADMIN: 1,
};

const table_actions = {
  SEARCH: 'search',
  CHANGE_PAGE: 'changePage',
  ON_SEARCH_CLOSE: 'onSearchClose',
  CHANGE_ROWS_PER_PAGE: 'changeRowsPerPage',
};

const paths = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
};

const expiryDaysForShare = 45;

const alert_messages = {
  INVITATION_SENT: '',
};

const page_limit = {
  limit: 6,
};

const routeEndpoints = {
  home: '/',
  ListingComponent: '/listing-component',
  addNewUser: '/listing-component/add-user',
  userMenu: '/userMenu',
  changePassword: '/changePassword',
  appUserList: '/app-user-listing',
  appUserDetail: '/app-user-detail',
  listingDetail: '/listing-detail',
  news: '/news',
  statistics: '/statistics',
  survey: '/survey',
  feedback: '/feedback',
};

// in seconds
const showAlertSeconds = 4;
/**
 * constant variable for the website
 */
const constant = {
  paths,
  apiUrl: apiURLBase,
  setSession,
  ApiKey,
  axios: $axios,
  table_actions,
  removeSession,
  getUserIdToken,
  getAccessToken,
  api_error_code,
  setAccessToken,
  setApiId,
  api_success_code,
  manageUserSession,
  // getCurrentLanguage,
  userType: user_type,
  spaceRegex: /^\S*$/,
  alert_messages,
  numberRegex: /^\d+$/,
  setAuthorizationToken,
  ALGORITHM: 'aes-256-gcm',
  hasConnection: navigator.onLine,
  mainMenuLinksArray: ['/dashboard'],
  // axiosIP: $axiosIP,
  // eslint-disable-next-line
  passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!_@./#&+-\d]{8,}$/,
  onlyNumberRegex: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*._\b)$/,
  // eslint-disable-next-line
  emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  page_limit,
  expiryDaysForShare,
  showAlertSeconds,
  routeEndpoints,
};
export default constant;
