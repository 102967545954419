import Utils from ".";
// import { UserData } from "../modal";

/**
 * get current language from locale
 */
// export const getCurrentLanguage = () => {
//   return localStorage.getItem("i18nextLng");
// };

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
  if (token) {
    // Utils.constants.axios.defaults.headers.common[
    //   "accesstoken"
    // ] = `bearer ${token}`;

    Utils.constants.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    Utils.constants.axios.defaults.headers.common["Authorization"] = "";
    delete Utils.constants.axios.defaults.headers.common["Authorization"];
  }
};

/**
 * set access token in axios instance header
 */
export const setApiId = (api_key?: string) => {
  Utils.constants.axios.defaults.headers.common["api_key"] = api_key;
};

/**
 * set user session in browser
 */
export const setSession = (payload: any) => {
  for (var k in payload) {
    var isCurrentValObject = typeof payload[k] === "object";
    if (isCurrentValObject && payload[k] !== null) {
      //if property is object then save it as string
      localStorage.setItem(k, JSON.stringify((payload as any)[k]));
    } else {
      localStorage.setItem(k, (payload as any)[k]);
    }
  }
  setAuthorizationToken(payload.accessToken);
};

export const removeSession = () => {
  // localStorage.clear();
  setAuthorizationToken("");
  window.location.reload();
};
/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }

  return accessToken;
};

/**
 * get user access token form localStorage
 */
export const setAccessToken = (accessToken: any) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};
/**
 * get user id form localStorage
 */
export const getUserIdToken = (history?: any) => {
  //   if (localStorage.getItem("accessToken")) {
  //     console.log("hello");
  //   } else {
  //     console.log("helloo");
  //     if (history) {
  //       console.log("hellooo");
  //       const location = {
  //         pathname: "/",
  //         state: { from: { pathname: window.location.pathname } },
  //       };
  //       console.log({ locationnn: location });
  //       history.push(location);
  //     }
  //   }
  let pathname = window.location.pathname + window.location.search;
  let token = localStorage.getItem("accessToken");

  if (!token && pathname !== "/") {
    localStorage.setItem("redirectTo", pathname);
  } else {
  }
  return token;
};

export const manageUserSession = (history: any) => {
  let { accessToken } = localStorage;
  if (accessToken) {
    //push user directly to dashboard in app
  } else {
    //do nothing reset user here
  }
};
