import { combineReducers } from 'redux';
import { globalLoaderReducer } from './rootReducer';
import {
  headerReducer,
  userMenuEditReducer,
} from '../components/header/reducer';
import { signInReducer } from '../screens/login/reducer';
import {
  ListingReducer,
  ListingAddParticipantReducer,
  ListingEditParticipantReducer,
  ListingDetailReducer,
} from '../screens/listingComponent/reducer';

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  signInReducer,
  globalLoaderReducer,
  ListingReducer,
  ListingAddParticipantReducer,
  ListingEditParticipantReducer,
  headerReducer,
  ListingDetailReducer,
  userMenuEditReducer,
});

export default rootReducer;
