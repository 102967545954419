export default {
  SIGN_IN: 'SIGN_IN',
  LOADING: 'LOADING',
  IS_LOADING: 'IS_LOADING',
  UPDATE_THEME_VALUE: 'UPDATE_THEME_VALUE',
  GET_USER_LIST: 'GET_USER_LIST',
  UPDATE_TRANSACTION_LIST: 'UPDATE_TRANSACTION_LIST',
  EDIT_THIS_USER: 'EDIT_THIS_USER',
  GET_PATIENT_LIST: 'GET_PATIENT_LIST',
  GET_DASHBOARD_COUNT: 'GET_DASHBOARD_COUNT',
  DELETE_TREATMENT_USER: 'DELETE_TREATMENT_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  GET_SYMPTOMS_USER_DETAIL: 'GET_SYMPTOMS_USER_DETAIL',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  ASSIGN_TREATMENT_COMPONENT: 'ASSIGN_TREATMENT_COMPONENT',
  USER_MENU_EDIT: 'USER_MENU_EDIT',
  GET_USER_MENU_DETAILS: 'GET_USER_MENU_DETAILS',
  GET_SETTINGS: 'GET_SETTINGS',
  GET_TERMS_AND_CONDITIONS: 'GET_TERMS_AND_CONDITIONS',
};
