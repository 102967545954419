import React, { useEffect, useState } from 'react';
import {
  Theme,
  makeStyles,
  Typography,
  Divider,
  Button,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Utils from '../../utils';
import { ReducersModel } from '../../model';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import Schema from '../../schema';
import { getUserMenuDetail, editUserMenu } from './action';
import UniModal from '../Modal';

const styles = makeStyles((theme: Theme) => ({
  userDetailsText: {
    margin: '10px 0px 10px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonFields: {
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
  },
  backButton: {
    width: '35%',
    borderRadius: '20px',
    height: '35px',
    marginTop: '20px',
    color: 'var(--water)',
    border: '1px solid var(--water)',
  },
  editButton: {
    backgroundColor: 'var(--water)',
    color: 'var(--white)',
  },
  mainWrapper: {
    marginLeft: '10px',
    marginRight: '20px',
  },
  innerWrapper: {
    width: '100%',
    marginTop: '10px',
  },
  nameTextField: {
    marginTop: '10px',
    width: '38%',
  },
  errorMessage: {
    width: '38%',
    fontSize: '12px',
    display: 'flex',
    height: '18px',
    color: 'red',
  },
  userEditButtonFields: {
    marginTop: '20px',
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
  },
  userEditSaveButton: {
    width: '35%',
    borderRadius: '20px',
    backgroundColor: 'var(--water)',
    color: 'var(--white)',
  },
  userEditCancelButton: {
    borderRadius: '30px',
    width: '35%',
    color: '#aaa',
  },
  tag: {
    marginRight: '5px',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 20px 20px 20px',
  },
}));
export default function UserMenu() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const { isUserEdit, firstName, lastName, email } = useSelector(
    (state: ReducersModel) => state.userMenuEditReducer
  );

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.IS_LOADING,
      payload: true,
    });
  }, [dispatch]);
  let _id = localStorage.getItem('id');
  useEffect(() => {
    dispatch(getUserMenuDetail(_id));
  }, [dispatch, _id]);

  const body = (
    <div className={classes.modalButton}>
      <div>
        <div style={{ margin: '0 0 20px 0', fontSize: '18px' }}>
          This is Under Development
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            style={{ backgroundColor: '#fff', color: 'var(--water)' }}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );

  const handleUserEditPage = () => {
    setIsOpen(true);
  };

  const handleUserMenuEditClose = () => {
    dispatch({
      type: Utils.ActionName.USER_MENU_EDIT,
      payload: {
        isUserEdit: false,
      },
    });
  };

  if (isUserEdit) {
    return (
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
        }}
        validationSchema={Schema.userMenuEditComponent}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(editUserMenu(values, setSubmitting));
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={classes.mainWrapper}>
              <div className={classes.innerWrapper}>
                <Typography>First Name</Typography>
                <TextField
                  className={classes.nameTextField}
                  type="text"
                  value={values.firstName}
                  size="small"
                  variant="outlined"
                  placeholder="enter first name"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.firstName && errors.firstName ? (
                  <Typography className={classes.errorMessage}>
                    {errors.firstName}
                  </Typography>
                ) : (
                  <Typography className={classes.errorMessage}></Typography>
                )}
              </div>
              <Divider />
              <div className={classes.innerWrapper}>
                <Typography>Last Name</Typography>
                <TextField
                  className={classes.nameTextField}
                  type="text"
                  value={values.lastName}
                  size="small"
                  variant="outlined"
                  placeholder="enter last name"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.lastName && errors.lastName ? (
                  <Typography className={classes.errorMessage}>
                    {errors.lastName}
                  </Typography>
                ) : (
                  <Typography className={classes.errorMessage}></Typography>
                )}
              </div>
              <Divider />
              <div className={classes.userEditButtonFields}>
                <Button
                  className={classes.userEditSaveButton}
                  variant="outlined"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  className={classes.userEditCancelButton}
                  onClick={handleUserMenuEditClose}
                  variant="outlined"
                >
                  {' '}
                  Cancel{' '}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else {
    return (
      <div>
        <div className={classes.userDetailsText}>
          <Typography className={classes.tag}>First Name : </Typography>
          <Typography style={{ marginTop: '5px' }}>{firstName}</Typography>
        </div>
        <Divider />
        <div className={classes.userDetailsText}>
          <Typography className={classes.tag}>Last Name :</Typography>
          <Typography style={{ marginTop: '5px' }}>{lastName}</Typography>
        </div>
        <Divider />
        <div className={classes.userDetailsText}>
          <Typography className={classes.tag}>Email Address :</Typography>
          <Typography style={{ marginTop: '5px' }}>
            {/* {localStorage.getItem("Username")} */}
            {email}
          </Typography>
        </div>
        <Divider />
        <div className={classes.userDetailsText}>
          <Typography className={classes.tag}>Phone No :</Typography>
          <Typography style={{ marginTop: '5px' }}>
            {localStorage.getItem('phoneNo') === 'Null' ||
            localStorage.getItem('phoneNo') === ''
              ? 'NA'
              : localStorage.getItem('phoneNo')}
          </Typography>
        </div>
        <Divider />
        <div className={classes.buttonFields}>
          <Button
            onClick={handleUserEditPage}
            className={`${classes.backButton} ${classes.editButton}`}
          >
            EDIT
          </Button>

          <Button
            onClick={() =>
              history.push(Utils.constants.routeEndpoints.ListingComponent)
            }
            className={classes.backButton}
          >
            BACK
          </Button>
        </div>
        <UniModal
          open={isOpen}
          heading=""
          handleClose={handleClose}
          body={body}
        />
      </div>
    );
  }
}
