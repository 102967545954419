// import Sessions from '../utils/constants';

class LoadingModel {
  isLoading = false;
  mainLoading = false;
}
class UserMenuEditModel {
  isUserEdit: boolean = false;
  id: string = '';
  data: any = [];
  firstName: string = '';
  lastName: string = '';
  email: string = '';
}

class signInModel {
  username: any =
    localStorage.getItem('email') === null ? '' : localStorage.getItem('email');
  password: any =
    localStorage.getItem('password') === null
      ? ''
      : localStorage.getItem('password');
  rememberMe = false;
  accountError: string = '';
  ipAddress: string = '';
}

class ListingComponentListModel {
  districtlist: any = [{ key: 'Select a district', value: '' }];
  district: any = '';
  block: any = '';
  blocklist: any = [{ key: 'Select a block', value: '' }];
  trade = '';
  listData: any = [];
  deleteId: string = '';
  listDataRaw: any = [];
  page: number = 1;
  limit: number = 10;
  search: string = '';
}

class ListingComponentAddUserListModel {
  name: any = '';
  lastName: any = '';
  fName: any = '';
  address: any = '';
  duration: any = '';
  district: any = '';
  image: any = '';
  status: any = '';
  isOpenAddModal: Boolean = false;
  selectedOption: any = 'Aadhar No';
  isEdit: Boolean = false;
  deleteId: string = '';
  id: string = '';
  isDesc: Boolean = false;
  isDeleteModal: Boolean = false;
  uploadS3Image: boolean = false;
  age: Date = new Date();
  sex: any = '';
  pNo: number | string = '';
  aadhar: number = 0;
  block: any = '';
  trade: any = '';
  qualification: any = '';
}
class ListingComponentEditUserModel {
  userDetails: any;
}

class HeaderReducerModel {
  isChangePasswordOpen: Boolean = false;
  isUserMenuOpen: Boolean = false;
}

class ListingDetailModel {
  detailData: any = {};
  id: string = '';
}

class ReducersModel {
  globalLoaderReducer: LoadingModel = new LoadingModel();
  signInReducer: signInModel = new signInModel();
  ListingReducer: ListingComponentListModel = new ListingComponentListModel();
  ListingAddParticipantReducer: ListingComponentAddUserListModel = new ListingComponentAddUserListModel();
  ListingEditParticipantReducer: ListingComponentEditUserModel = new ListingComponentEditUserModel();
  headerReducer: HeaderReducerModel = new HeaderReducerModel();
  ListingDetailReducer: ListingDetailModel = new ListingDetailModel();
  userMenuEditReducer: UserMenuEditModel = new UserMenuEditModel();
}

export {
  signInModel,
  LoadingModel,
  ReducersModel,
  ListingComponentListModel,
  ListingComponentAddUserListModel,
  ListingComponentEditUserModel,
  HeaderReducerModel,
  ListingDetailModel,
  UserMenuEditModel,
};
