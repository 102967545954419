import React, { useEffect } from 'react';
import Schema from '../../schema';
import { FormControl, makeStyles, Theme, Button } from '@material-ui/core';
import PasswordInput from '../inputs/passwordInput';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { ReducersModel } from '../../model';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changePassword } from './action';
import Utils from '../../utils';
const styles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    width: '60%',
    marginLeft: '50px',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: { marginLeft: '10px', marginTop: '10px' },
  oldPassword: { width: '100%', margin: '10px auto' },
  password: { width: '100%', margin: '10px auto' },
  confirmPassword: { width: '100%', margin: '10px auto' },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  buttonFields: {
    marginTop: '20px',
    display: 'flex',
    width: '100%',

    justifyContent: 'space-between',
  },
  saveButton: {
    width: '35%',
    borderRadius: '20px',
    backgroundColor: 'var(--water)',
    color: 'var(--white)',
    [theme.breakpoints.down("sm")]: {
      width: '100px',
      borderRadius: '20px',
      backgroundColor: 'var(--water)',
      color: 'var(--white)',
      fontSize:'10px',
      outline:'none'
    }
  },
  cancelButton: {
    borderRadius: '30px',
    border: '1px solid var(--water)',
    width: '35%',
    color: 'var(--water)',
    [theme.breakpoints.down("sm")]: {
      borderRadius: '30px',
      border: '1px solid var(--water)',
      width: '100px',
      color: 'var(--water)',
      marginLeft:"15px"
    }
  },
}));

export default function ChangePassword() {
  const { username, password } = useSelector(
    (state: ReducersModel) => state.signInReducer
  );
  console.log('username & password', username, password);
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.IS_LOADING,
      payload: false,
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className={classes.mainWrapper}>
        <Formik
          initialValues={{
            previousOldPassword: localStorage.getItem('Password'),
            oldPassword: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Schema.changeOldToNewPassword}
          onSubmit={(values, { setSubmitting }) => {
            if (values.password === values.confirmPassword) {
              dispatch(changePassword(values, setSubmitting));
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.oldPassword}>
                <PasswordInput
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                  placeholder={'Old Password'}
                  name={'oldPassword'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values.oldPassword}
                  label={'Enter old password'}
                  isRequired={true}
                />
              </FormControl>
              <FormControl fullWidth className={classes.password}>
                <PasswordInput
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  placeholder={'new Password'}
                  name={'password'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values.password}
                  label={'Enter new password'}
                  isRequired={true}
                />
              </FormControl>
              <FormControl fullWidth className={classes.confirmPassword}>
                <PasswordInput
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  placeholder={'Confirm Password'}
                  name={'confirmPassword'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values.confirmPassword}
                  label={'Confirm New Password'}
                  isRequired={true}
                />
              </FormControl>
              <div className={classes.buttonFields}>
                <Button
                  className={classes.saveButton}
                  variant="outlined"
                  type="submit"
                >
                  Change Password
                </Button>
                <Button
                  className={classes.cancelButton}
                  variant="outlined"
                  onClick={() => {
                    history.push(
                      Utils.constants.routeEndpoints.ListingComponent
                    );
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
