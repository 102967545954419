const EYE_OPEN = '../images/Eye Icon.svg';
const EYE_CLOSE = '../images/Icon ionic-md-eye-off.svg';
const SEARCH_ICON = '../images/Group 1535.svg';

const CANCEL = '../images/cancel.svg';

const FILTER = '../images/dashboard.svg';
const SMALL_CROSS = '../images/small_cross.svg';
const SMALL_TICK = '../images/small_tick.svg';
const FILTER_ICON = '../images/filter-icon.svg';
const DOWN_ARROW = '../images/icon - arrow-down.svg';

const USER_ICON = '../images/UserIcon.png';

const FIRST_PAGE_ICON = '../images/Group 5.svg';
const LAST_PAGE_ICON = '../images/Group 5 (1).svg';
const KEY_ARROW_LEFT = '../images/Combined Shape Copy 12.svg';
const KEY_ARROW_RIGHT = '../images/Combined Shape Copy 12(1).svg';

const LOGO = './images/JJMLogo.png';
const WHITE_BOX = './images/pm_samwad.jpg';
const EXIT_TO_PAGE = '../images/iconslogout.png';
const EMBLEM = './images/Emblem_of_India_W.svg.png';

const CLOSE = './images/close 1.svg';
const HEAD_ARROW = './images/right-arrow.png';
const CHANGE_PASSWORD = './images/ChangePassword.png';

const LocalImages = {
  EYE_OPEN,
  EYE_CLOSE,
  SEARCH_ICON,

  CANCEL,

  FILTER,
  SMALL_CROSS,
  SMALL_TICK,
  FILTER_ICON,
  DOWN_ARROW,

  LOGO,
  WHITE_BOX,
  EMBLEM,

  CLOSE,
  EXIT_TO_PAGE,
  USER_ICON,
  FIRST_PAGE_ICON,
  LAST_PAGE_ICON,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
  HEAD_ARROW,
  CHANGE_PASSWORD,
};

export default LocalImages;
