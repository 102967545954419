import Utils from "../../utils";
import {HeaderReducerModel, UserMenuEditModel} from "../../model"

export const headerReducer = (state: HeaderReducerModel = new HeaderReducerModel(), action: any) => {
    switch(action.type) {
        case Utils.ActionName.CHANGE_PASSWORD: 
        return {...state, ...action.payload};
        default: return state;
    }
}
export const userMenuEditReducer = (state: UserMenuEditModel = new UserMenuEditModel(), action: any) => {
    switch(action.type) {
        case Utils.ActionName.USER_MENU_EDIT: 
        return {...state, ...action.payload};
        default: return state;
    }
}