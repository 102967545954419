import Utils from '../../utils';
import {
  ListingComponentListModel,
  ListingComponentAddUserListModel,
  ListingDetailModel,
  ListingComponentEditUserModel,
} from '../../model';

export const ListingReducer = (
  state: ListingComponentListModel = new ListingComponentListModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_USER_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ListingAddParticipantReducer = (
  state: ListingComponentAddUserListModel = new ListingComponentAddUserListModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.UPDATE_TRANSACTION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ListingEditParticipantReducer = (
  state: ListingComponentEditUserModel = new ListingComponentEditUserModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EDIT_THIS_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const ListingDetailReducer = (
  state: ListingDetailModel = new ListingDetailModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_USER_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
