import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';

const tradeOptions = [
  'PLUMBER',
  'ELECTRICIAN',
  'MASON',
  'PUMP OPERATOR',
  'FITTER',
  'MOTOR MECHANIC',
];

const data = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augest',
    'September',
    'October',
    'November',
    'December',
  ],
  datasets: [
    {
      label: 'User Trade',
      backgroundColor: '#367397',
      borderColor: '#367397',
      borderWidth: 1,
      hoverBackgroundColor: '#367397',
      hoverBorderColor: '#367397',
      data: [45, 30, 55, 81, 65, 26, 46, 78, 89, 85, 67, 55],
    },
  ],
};

const data1 = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augest',
    'September',
    'October',
    'November',
    'December',
  ],
  datasets: [
    {
      label: 'People got job',
      backgroundColor: '#367397',
      borderColor: '#367397',
      borderWidth: 1,
      hoverBackgroundColor: '#367397',
      hoverBorderColor: '#367397',
      data: [65, 59, 80, 81, 56, 55, 40, 56, 55, 40, 56, 55],
    },
    {
      label: 'People got trained',
      backgroundColor: '#8fbfe2',
      borderColor: '#8fbfe2',
      borderWidth: 1,
      hoverBackgroundColor: '#8fbfe2',
      hoverBorderColor: '#8fbfe2',
      data: [45, 30, 55, 81, 65, 28, 46, 78, 89, 85, 67, 55],
    },
  ],
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    udContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      flexDirection: 'column',
      padding: '40px',
    },

    text: {
      fontSize: '21px',
    },
  })
);

const index = () => {
  const classes = styles();
  return (
    <div className={classes.udContainer}>
      <Typography style={{ fontSize: '20px' }}>User Analytics</Typography>
      <div style={{ width: '100%' }}>
        <Bar
          data={data}
          width={50}
          height={300}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>

      <Typography style={{ fontSize: '20px', marginTop: '70px' }}>
        Job Analytics
      </Typography>
      <div style={{ width: '100%' }}>
        <Line
          data={data1}
          width={50}
          height={300}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    </div>
  );
};

export default index;
