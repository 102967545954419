import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Utils from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    links: {
      padding: '20px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '7px',
        width: '1000px',
      },
    },
    isActive: {
      borderLeft: '3px solid var(--white)',
    },
  })
);
const SidePanel = () => {
  const classes = useStyles();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch({
      type: Utils.ActionName.UPDATE_TRANSACTION_LIST,
      payload: {
        isOpenAddModal: false,
        isEdit: false,
      },
    });
  };

  return (
    <ul>
      <li
        className={`${classes.links} ${
          pathname === '/listing-component' ? classes.isActive : ''
        }`}
        onClick={handleCloseModal}
      >
        <Link to="/listing-component">Trained People List</Link>
      </li>
      <li
        className={`${classes.links} ${
          pathname === '/news' ? classes.isActive : ''
        }`}
      >
        <Link to="/news">News</Link>
      </li>
      <li
        className={`${classes.links} ${
          pathname === '/statistics' ? classes.isActive : ''
        }`}
      >
        <Link to="/statistics">Statistics</Link>
      </li>

      <li
        className={`${classes.links} ${
          pathname === '/survey' ? classes.isActive : ''
        }`}
      >
        <Link to="/survey">Survey</Link>
      </li>

      <li
        className={`${classes.links} ${
          pathname === '/feedback' ? classes.isActive : ''
        }`}
      >
        <Link to="/feedback">Feedback</Link>
      </li>
    </ul>
  );
};

export default SidePanel;
