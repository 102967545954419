import { LoadingModel } from "../model";
import Utils from "../utils";

/**
 * Global Loading reducer
 */
export const globalLoaderReducer = (
  state: LoadingModel = new LoadingModel(),
  action: any
) => {
  //@ts-ignore
  switch (action.type) {
    case Utils.ActionName.LOADING:
      return { ...state, mainLoading: action.payload };
    case Utils.ActionName.IS_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};
