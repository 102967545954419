import axios from "axios";
/**
 * function to remove all spaces from a given string.
 * @param value
 */
const removeSpaces = (value: string) => {
  return value.replace(/\s/g, "");
};

const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

function setAuthorizationToken() {
  // @ts-ignore
  // constants.setAuthorizationToken(localStorage.getItem('accessToken'));
  // @ts-ignore
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("accessToken")}`;
}

// for 2 decimal places at max
function maxDecimalValue(value: any, digitsAfterDecimal: any) {
  return +parseFloat(value).toFixed(digitsAfterDecimal);
}

// always 2 decimal values
function alwaysDecimalValue(value: any, digitsAfterDecimal: any) {
  return (Math.round(value * 100) / 100).toFixed(digitsAfterDecimal);
}

// get local storage value
const getStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

const numberWithCommas = (x: any) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return "-";
};

const getPagewiseIndex = (page: number, index: number) => {
  return 10 * (page - 1) + index + 1;
};

const CommonFunctions = {
  removeSpaces,
  generateRandomNumber,
  setAuthorizationToken,
  maxDecimalValue,
  alwaysDecimalValue,
  getStorageItem,
  numberWithCommas,
  getPagewiseIndex,
};

export default CommonFunctions;
