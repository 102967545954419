import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  // Redirect
} from 'react-router-dom';
import PrivateRoute from './private';
import Utils from '../utils';
import moment from 'moment';
import UserMenu from '../components/header/userMenu';
import ChangePassword from '../components/header/changePassword';
import UnderDevelopment from '../screens/underDevelopment';
import AppChart from '../screens/statics';
// Private Routes
const ListingComponent = lazy(() => import('../screens/listingComponent'));
const ListingDescription = lazy(
  () => import('../screens/listingComponent/ListingDescription')
);

// Public Routes
const Login = lazy(() => import('../screens/login'));

/**
 * Root router component
 * @param props parameter for Router function
 */
const Router: React.FC = () => {
  // const pathname = Utils.constants.paths;

  if (localStorage.getItem('browserLanguageCode')) {
    Utils.constants.axios.defaults.headers.common[
      'lang'
    ] = localStorage.getItem('browserLanguageCode');
    if (localStorage.getItem('browserLanguageCode') === 'zh') {
      moment.locale('zh-cn');
    } else {
      if (localStorage.getItem('browserLanguageCode')) {
        moment.locale(`${localStorage.getItem('browserLanguageCode')}`);
      } else {
        moment.locale('en');
      }
    }
  } else {
    Utils.constants.axios.defaults.headers.common['lang'] = 'en';
  }

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}{' '}
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props: any) => (
              <Suspense
                fallback={
                  <div>
                    <span>Loading....</span>
                  </div>
                }
              >
                {' '}
                <React.Fragment>
                  {/* {Utils.constants.getUserIdToken() ? (
                    // newValue <InnerContainer {...props} >
                    <Redirect to="/dashboard" />
                  ) : ( */}
                  <Login {...props} />

                  {/* )} */}
                </React.Fragment>
              </Suspense>
            )}
          />

          {/* Add your component here at component prop */}

          <PrivateRoute
            path={Utils.constants.routeEndpoints.ListingComponent}
            component={ListingComponent}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.userMenu}
            component={UserMenu}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.changePassword}
            component={ChangePassword}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.listingDetail}
            component={ListingDescription}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.news}
            component={UnderDevelopment}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.news}
            component={UnderDevelopment}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.statistics}
            component={AppChart}
          />
          <PrivateRoute
            path={Utils.constants.routeEndpoints.survey}
            component={UnderDevelopment}
          />

          <PrivateRoute
            path={Utils.constants.routeEndpoints.feedback}
            component={UnderDevelopment}
          />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
