export default {
  login: '/login',
  logout: '/logout',
  addNewUser: '/add-user',
  getAdminDetail: '/getadmindetail',
  listingComponent: '/user-list',
  getUserDetail: '/getuserdetail',
  deleteUserId: '/change-user-status',
  changePassword: '/change-password',
  updateUser: '/update-user',
};
