import api from './api';
import showAlert from './alert';
import endPoints from './endPoints';
import constants from './constants';

import ActionName from './actionNames';
import CommonFunctions from './commonFunctions';

const Utils = {
  api: api,
  showAlert: showAlert,
  endPoints: endPoints,
  constants: constants,
  ActionName: ActionName,
  CommonFunctions: CommonFunctions,
};

export default Utils;
