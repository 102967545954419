import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Backdrop,
  Drawer,
} from '@material-ui/core';
import Header from '../../components/header/header';
import Spinner from '../spinner';
import { ReducersModel } from '../../model';
import { useSelector } from 'react-redux';
import SidePanel from '../sidePanel';
import LocalImages from '../../utils/images';

const drawerWidth = 190;
const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: { position: 'relative' },
    section: { display: 'flex', minHeight: '100vh' },
    rightContainer: {
      width: 'calc(99.8% - 60px)',
      backgroundColor: 'var(--background-grey)',
      marginLeft: '62px',
      marginTop: '48px',
    },
    titleContainer: {
      width: '100%',
      backgroundColor: 'var(--white)',
      height: '46px',
      '& .MuiBreadcrumbs-li': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
      },
      '& .MuiLink-root': {
        cursor: 'pointer',
      },
    },
    titleText: {
      color: 'var(--title-color)',
      padding: '11px 0px',
    },
    slash: {
      color: 'var(--title-color)',
      padding: '0 4px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    rowDisplay: {
      display: 'flex',
      alignItems: 'center',
    },
    main__wrapper: {
      display: 'flex',
      '& .MuiTab-wrapper': {
        alignItems: 'end',
      },
      '& .MuiTab-root': {
        padding: '5px 12px 20px 20px',
        textAlign: 'left',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'var(--white)',
        width: '3px',
        left: '0px',
      },
    },
    drawer__wrapper: {
      width: '190px',
      color: 'var(--white)',
       height: '100vh',
      
      [theme.breakpoints.down("sm")]: {
        width: '80px',
        color: 'var(--white)',
         height: '100vh',
      }
    },
    drawPaper: {
      width: '190px',
      backgroundColor: 'var(--water)',
      position: 'absolute',
      border: '1px solid var(--water)',
      color: 'var(--white)',
      height:"100%",
      [theme.breakpoints.down("sm")]: {
        width: '80px',
        backgroundColor: 'var(--water)',
        position: 'absolute',
        border: '1px solid var(--water)',
        color: 'var(--white)',
      }
    },
    drawerHeading: {
      color: '#fff',
      height: '94px',
      width: '100%',
      fontFamily: 'roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30px',
      textAlign: 'center',
      lineHeight: '35px',
      padding: '30px 0',
    },
    wrapper: {
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("sm")]: {
        width: `calc(100% - ${80}px)`,
      }
    },
    contentWrapper: {
      width: '100%',
      padding: '8px',
    },
    emblemContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
  })
);

export default function Container({ children }: any) {
  const classes = styles();

  const { isLoading } = useSelector(
    (state: ReducersModel) => state.globalLoaderReducer
  );
  return (
    <div className={classes.mainContainer}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <Spinner />
      </Backdrop>
      <div className={classes.main__wrapper}>
        <Drawer
          className={classes.drawer__wrapper}
          variant="permanent"
          anchor="left"
          classes={{ paper: classes.drawPaper }}
        >
          {/* <Typography variant='h2' className={classes.drawerHeading}>
            OAKWOOD
          </Typography> */}
          <div className={classes.emblemContainer}>
            <img
              src={LocalImages.LOGO}
              alt="logo"
              style={{ width: '80px', height: '75px' }}
            />
          </div>

          <SidePanel />
        </Drawer>

        <div className={classes.wrapper}>
          <Header />
          <div className={classes.contentWrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
}
