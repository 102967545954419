import React from 'react';

import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    udContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    underDevelopment: {
      width: '400px',
      height: '350px',
      fontSize: '21px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor: 'var(--water)',
      // color: 'var(--primary)',
    },
    text: {
      fontSize: '21px',
    },
  })
);

const index = () => {
  const classes = styles();
  return (
    <div className={classes.udContainer}>
      <div className={classes.underDevelopment}>
        <Typography className={classes.text}>
          {' '}
          This page is Under Development!
        </Typography>
      </div>
    </div>
  );
};

export default index;
