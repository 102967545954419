import * as Yup from 'yup';

const LoginSchema = (t: any) => {
  return Yup.object().shape({
    email: Yup.string().trim().required('Email is required').email().max(50),
    password: Yup.string()
      .required('Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
  });
};

const today = new Date();
today.setHours(0, 0, 0, 0);
const assignTreatment = () => {
  return Yup.object().shape({
    // name: Yup.string(),
    startDate: Yup.date().min(today, 'Enter valid date'),
    notes: Yup.string()
      .min(2, 'Too Short')
      .max(250, 'Too Long')
      .required('Please enter Treatment notes'),
    frequency: Yup.string(),
  });
};

/* forgot password screen schema validation */

const forgetPassword = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('user email is required'),
  });
};
const changePassword = () => {
  return Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
  });
};

/* change password screen schema validation */

const changeOldToNewPassword = () => {
  return Yup.object().shape({
    previousOldPassword: Yup.string(),
    oldPassword: Yup.string()
      .required('OLD PASSWORD REQUIRED')
      .oneOf(
        [Yup.ref('previousOldPassword'), ''],
        'PASSWORD IS NOT SAME AS OLD PASSWORD'
      ),
    password: Yup.string()
      .required('ENTER NEW PASSWORD ')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),

    confirmPassword: Yup.string()
      .required('CONFIRM NEW PASSWORD')
      .oneOf([Yup.ref('password'), ''], 'PASSWORD DONT MATCH'),
  });
};

/* add new participant schema validation */

const addNewListingComponent = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name is too short')
      .max(100, 'Name is too long')
      .required('Name of the participant is required'),
    lastName: Yup.string()
      .min(2, 'Name is too short')
      .max(100, 'Name is too long')
      .required('Name of the participant is required'),
    fName: Yup.string()
      .min(2, 'Name is too short')
      .max(100, 'Name is too long')
      .required('Father Name or Husband Name is required'),
    district: Yup.string().required('Select a district'),
    block: Yup.string().required('Select a block'),
    qualification: Yup.string().required('Select the qualification'),
    sex: Yup.string().required('Select the gender'),
    trade: Yup.string().required('Select the trade'),
    age: Yup.date()
      .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years')
      .required('Required'),
    address: Yup.string()
      .max(250, 'Description is too long')
      .required('description is required'),
    pNo: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(10, 'to short')
      .max(10, 'to long'),
    aadhar: Yup.string()
      .required('Aadhar number is required')
      .matches(phoneRegExp, 'Aadhar number is not valid')
      .min(12, 'to short')
      .max(12, 'to long'),
  });
};

/* User menu edit screen schema validation */

const userMenuEditComponent = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'too short')
      .max(20, 'too long')
      .required('enter first name'),
    lastName: Yup.string()
      .min(2, 'too short')
      .max(20, 'too long')
      .required('enter last name'),
  });
};

/* Reset password screen schema validation */

const resetPassword = () => {
  return Yup.object().shape({
    password: Yup.string().required('Password is required'),
  });
};

const Details = () => {
  return Yup.object().shape({
    name: Yup.string().required('name is required'),
    prescription: Yup.string().required('Prescription is required'),
    fromDate: Yup.date()
      .min(new Date('01-01-2019'))
      .max(new Date())
      .required('Date is required'),
    toDate: Yup.date()
      .min(new Date())
      .max(new Date('01-01-2022'))
      .required(' Date is required'),
    detail: Yup.string()
      .min(10, 'This is too short')
      .required('Details are required'),
  });
};

const Schema = {
  LoginSchema,
  forgetPassword,
  resetPassword,
  addNewListingComponent,
  Details,
  changePassword,
  changeOldToNewPassword,
  assignTreatment,
  userMenuEditComponent,
};

export default Schema;
