import React, { Suspense } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import Container from "../components/container";

//custom imports below
import Utils from "../utils";

interface Props {
  path: string;
  component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const history = useHistory();
  return (
    <Route
      exact
      {...rest}
      render={(props) => (
        <Suspense fallback={""}>
          {Utils.constants.getUserIdToken(history) ? (
            <Container {...props}>
              <Component {...props} />
            </Container>
          ) : (
            <Redirect to="/" />
          )}
        </Suspense>
      )}
    />
  );
};
export default PrivateRoute;
