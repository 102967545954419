import React, { useState } from 'react';
import {
  createStyles,
  FormLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
} from '@material-ui/core';
import LocalImages from '../../utils/images';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    passwordIcon: {
      cursor: 'pointer',
      color: 'white',
    },
    inputField: {
      '& .MuiInputBase-input': {
        font: 'normal normal normal 14px/14px Roboto',
        letterSpacing: '0.07px',
        opacity: 1,
      },
      '& .MuiFormLabel-root': {
        font: 'normal normal normal 12px/21px Roboto',
        letterSpacing: '0.07px',
        opacity: 1,
      },
    },
    textField: {
      height: '100%',
      width: '100%',
      marginBottom: '1rem',
      marginTop: '1rem',
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--water)',
        color: 'var(--water)',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: 'var(--error)',
        fontSize: '15px',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(28px, -16px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root': {
        height: '55px',
        color: 'var(--water)',
      },
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 32px',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'var(--water)',
      },
    },
    textField1: {
      height: '100%',
      width: '100%',
      marginBottom: '1rem',
      marginTop: '1rem',
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--primary)',
        color: 'var(--primary)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(28px, -16px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root': {
        height: '55px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 32px',
        
      },
      // "& .MuiFormHelperText-root.Mui-error":{
      //   color:"white",
      //   fontSize:"15px"
      // },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'var(--primary)',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: 'var(--error)',
      },
    },
    required: {
      color: 'white',
    },
    formLabel: {
      fontSize: '24px',
      lineHeight: '1.63',
      marginBottom: '0',
      // paddingLeft: "8px",
      opacity: '0.5',
      color: 'var(--blue-grey)',
      '& .MuiFormHelperText-root.Mui-error': {
        color: 'var(--error)',
      },
    },
  })
);
interface Props {
  label?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  type?: string;
  onBlur?: any;
  values?: any;
  onChange?: any;
  style?: any;
  helperText?: any;
  error?: any;
  isRequired?: boolean;
  isLogin?: boolean;
}

export default function PasswordInput({
  label,
  placeholder,
  id,
  name,
  type,
  onBlur,
  values,
  onChange,
  style,
  helperText,
  error,
  isRequired,
  isLogin,
}: Props) {
  const classes = styles();

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      focused
      variant="outlined"
      onBlur={onBlur}
      value={values ? values : ''}
      onChange={onChange}
      placeholder={placeholder}
      helperText={helperText}
      error={error}
      name={name}
      label={
        <FormLabel className={classes.formLabel}>
          {label}
          {isRequired ? (
            <span className={classes.required}> *</span>
          ) : (
            <span></span>
          )}
        </FormLabel>
      }
      type={showPassword ? 'text' : 'password'}
      fullWidth
      size={'small'}
      className={isLogin ? classes.textField1 : classes.textField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showPassword ? (
              <Tooltip title={'Hide Password'} placement={'top'}>
                <img
                  src={LocalImages.EYE_CLOSE}
                  alt={'eye'}
                  onClick={togglePassword}
                  className={classes.passwordIcon}
                />
              </Tooltip>
            ) : (
              <Tooltip title={'Show Password'} placement={'top'}>
                <img
                  src={LocalImages.EYE_OPEN}
                  alt={'eye'}
                  onClick={togglePassword}
                  className={classes.passwordIcon}
                />
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
