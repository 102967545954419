import Utils from "../utils";
// import { logout } from "../components/header/action";
/**
 * function to check if code matches to user invalid.
 * @param data
 */
let isRefreshingToken: boolean = false;
const checkUserValidation = (data: any) => {
  if (data) {
    const { statusCode } = data,
      {
        sessionExpired,
        unauthorized,
        accessDenied,
      } = Utils.constants.api_error_code;
    if (statusCode) {
      return (
        statusCode === sessionExpired ||
        statusCode === unauthorized ||
        statusCode === accessDenied
      );
    }
    let status = data.status;
    if (status) {
      return (
        status === sessionExpired ||
        status === unauthorized ||
        status === accessDenied
      );
    }

    return false;
  }
  return false;
};

// when user session expires and need to get a new accessToken using refreshToken
export const getAccessTokenUsingRefreshToken = (callback: any) => {
  console.log("getting accessToken by refreshToken");

  if (!navigator.onLine) {
    //check if user is online or not
    Utils.showAlert(1, "Please check your internet connection!");
    // setSubmitting(false);
    return;
  }

  if (!isRefreshingToken) {
    if (localStorage.getItem("refreshToken") !== null) {
      const dataToSend = `?refreshToken=${localStorage.getItem(
        "refreshToken"
      )}&accessToken=${localStorage.getItem("accessToken")}`;
      isRefreshingToken = true;
      Utils.api.getApiCall(
        "refresh",
        dataToSend,
        (respData: any) => {
          const { data } = respData;
          console.log(data.statusCode);
          if (
            data.statusCode ===
            (Utils.constants.api_success_code.success ||
              Utils.constants.api_success_code.postSuccess)
          ) {
            localStorage.setItem("accessToken", data.data.accessToken);
            localStorage.setItem("refreshToken", data.data.refreshToken);
            Utils.constants.setAuthorizationToken(data.data.accessToken);
            isRefreshingToken = false;
            // window.location.reload();
            callback();
          } else {
            isRefreshingToken = false;
            // logout();
          }
        },
        (error: any) => {
          isRefreshingToken = false;
          // logout();
        }
      );
    } else {
      isRefreshingToken = false;
      // logout();
    }
  } else {
    let checkRefreshCall = new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        if (!isRefreshingToken) {
          resolve();
        }
      }, 2000);
    });
    checkRefreshCall
      .then((success: any) => {
        callback();
      })
      .catch((error: any) => {
        // logout();
      });
  }
};

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
// const postCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {};

const postApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  // @ts-ignore
  Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
  Utils.constants.axios
    .post(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      console.log("API Call error", error);
      if (error.code === "ECONNABORTED") {
        const payload = {
          data: {
            statusCode: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        const data = error.response.data;
        if (checkUserValidation(data)) {
          //if user session expired
          setTimeout(() => {
            // logOutApiCall();
            if (localStorage.getItem("accessToken") !== null) {
              getAccessTokenUsingRefreshToken(() =>
                postApiCall(endPoint, params, successCallback, errorCallback)
              );
            }
          }, 1000);
          // errorCallback(error.response);
        } else {
          console.log("response: ", error.response);
          errorCallback(error.response);
        }
      } else if (!error.response) {
        const payload = {
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (
  endPoint: string,
  params = "",
  successCallback: Function,
  errorCallback: Function
) => {
  console.log({ endPoint, params });
  // @ts-ignore
  Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
  Utils.constants.axios
    .get(Utils.constants.apiUrl + endPoint + params, {})
    .then((response: any) => {
      console.log(response);
      successCallback(response);
    })
    .catch((error: any) => {
      console.log("API Call error", error);
      if (error.code === "ECONNABORTED") {
        const payload = {
          data: {
            statusCode: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        const data = error.response.data;

        if (checkUserValidation(data)) {
          //if user session expired
          // ;
          console.log("session expired for user");
          //   Utils.showAlert(2, data.message);
          setTimeout(() => {
            // logOutApiCall();
            if (localStorage.getItem("accessToken") !== null) {
              getAccessTokenUsingRefreshToken(() =>
                getApiCall(endPoint, params, successCallback, errorCallback)
              );
            }
          }, 1000);
          // errorCallback(error.response);
        } else {
          console.log("response: ", error.response);
          errorCallback(error.response);
        }
      } else if (!error.response) {
        const payload = {
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  console.log({ endPoint, params });
  // @ts-ignore
  Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
  Utils.constants.axios
    .patch(endPoint, params)
    .then((response: any) => {
      console.log("response: ", response);
      successCallback(response);
    })
    .catch((error: any) => {
      console.log("API Call error", error);
      if (error.code === "ECONNABORTED") {
        const payload = {
          data: {
            statusCode: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        const data = error.response.data;
        if (checkUserValidation(data)) {
          setTimeout(() => {
            if (localStorage.getItem("accessToken") !== null) {
              getAccessTokenUsingRefreshToken(() =>
                patchApiCall(endPoint, params, successCallback, errorCallback)
              );
            }
          }, 1000);
        } else {
          console.log("response: ", error.response);
          errorCallback(error.response);
        }
      } else if (!error.response) {
        const payload = {
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        };
        errorCallback(payload);
      }
    });
};
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const putApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  console.log({ endPoint, params, successCallback });
  // @ts-ignore
  Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
  Utils.constants.axios
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      console.log("API Call error", error);
      if (error.code === "ECONNABORTED") {
        const payload = {
          data: {
            statusCode: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        const data = error.response.data;

        if (checkUserValidation(data)) {
          //if user session expired
          console.log("session expired for user");

          setTimeout(() => {
            if (localStorage.getItem("accessToken") !== null) {
              getAccessTokenUsingRefreshToken(() =>
                putApiCall(endPoint, params, successCallback, errorCallback)
              );
            }
          }, 1000);
        } else {
          console.log("response: ", error.response);
          errorCallback(error.response);
        }
      } else if (!error.response) {
        const payload = {
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

const deleteApiCall = (
  endPoint: string,
  params = "",
  successCallback: Function,
  errorCallback: Function
) => {
  console.log({ endPoint, params });
  // @ts-ignore
  Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
  Utils.constants.axios
    .delete(Utils.constants.apiUrl + endPoint + params, {})
    .then((response: any) => {
      console.log(response);

      successCallback(response);
    })
    .catch((error: any) => {
      console.log("API Call error", error);
      if (error.code === "ECONNABORTED") {
        const payload = {
          data: {
            statusCode: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        const data = error.response.data;
        if (checkUserValidation(data)) {
          console.log("session expired for user");

          setTimeout(() => {
            if (localStorage.getItem("accessToken") !== null) {
              getAccessTokenUsingRefreshToken(() =>
                deleteApiCall(endPoint, params, successCallback, errorCallback)
              );
            }
          }, 1000);
        } else {
          console.log("response: ", error.response);
          errorCallback(error.response);
        }
      } else if (!error.response) {
        const payload = {
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 * Logout API
 */
const logOutApiCall = () => {
  if (!navigator.onLine) {
    return;
  }

  Utils.constants.getAccessToken();
  Utils.constants.removeSession();
  window.location.replace(window.location.origin);

  Utils.constants.removeSession();
};

// const getApiCallFactory = (
//   endPoint: string,
//   params: string = "",
//   successCallback: Function,
//   errorCallback: Function
// ) => {
//   // @ts-ignore
//   // Utils.constants.setAuthorizationToken(localStorage.getItem("accessToken"));
//   Utils.constants.axios
//     .get(endPoint + params, {})
//     .then((response: any) => {
//       successCallback(response);
//     })
//     .catch((error: any) => {
//       console.log("API Call error", error);
//       if (error.code === "ECONNABORTED") {
//         const payload = {
//           data: {
//             statusCode: 408,
//           },
//         };
//         errorCallback(payload);
//       } else if (error.response) {
//         const data = error.response.data;

//         if (checkUserValidation(data)) {
//           //if user session expired
//           // ;
//           console.log("session expired for user");
//           //   Utils.showAlert(2, data.message);
//           setTimeout(() => {
//             // logOutApiCall();
//             if (localStorage.getItem("accessToken") !== null) {
//               getAccessTokenUsingRefreshToken(() =>
//                 getApiCallFactory(
//                   endPoint,
//                   params,
//                   successCallback,
//                   errorCallback
//                 )
//               );
//             }
//           }, 1000);
//           // errorCallback(error.response);
//         } else {
//           console.log("response: ", error.response);
//           errorCallback(error.response);
//         }
//       } else if (!error.response) {
//         const payload = {
//           data: {
//             statusCode: "",
//             message: "Please try again later",
//           },
//         };
//         errorCallback(payload);
//       }
//     });
// };

/**
 * export all function
 */
export default {
  putApiCall,
  getApiCall,
  postApiCall,
  patchApiCall,
  deleteApiCall,
  logOutApiCall,
  checkUserValidation,
};
