import Utils from '../../utils';

export const logout = () => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, 'PLEASE CHECK YOUR INTERNET');
      dispatch({
        type: Utils.ActionName.IS_LOADING,
        payload: false,
      });
      return;
    }

    const dataToSend = {};

    Utils.api.patchApiCall(
      Utils.endPoints.logout,
      dataToSend,
      (respData: any) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        Utils.constants.setAuthorizationToken();
        window.location.replace(window.location.origin);
      },
      (err: any) => {
        let { data } = err;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.IS_LOADING,
          payload: false,
        });
      }
    );
  };
};

export const changePassword = (values: any, setSubmitting: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, 'PLEASE CHECK YOUR INTERNET');
      dispatch({
        type: Utils.ActionName.IS_LOADING,
        payload: false,
      });
      return;
    }
    const { oldPassword, password, confirmPassword } = values;
    const dataToSend = {
      oldPassword: oldPassword,
      password: password,
      confirmPassword: confirmPassword,
    };

    Utils.api.patchApiCall(
      Utils.endPoints.changePassword,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (password === oldPassword) {
          Utils.showAlert(
            2,
            'New Password must be different from old password'
          );
        } else if (
          data.statusCode === Utils.constants.api_success_code.success
        ) {
          setSubmitting(false);
          Utils.showAlert(1, data.message);
          localStorage.setItem('firstName', data.firstName);
          localStorage.setItem('lastName', data.lastName);
          dispatch(logout());
        } else {
          Utils.showAlert(2, data.message);
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });
        }
      },
      (err: any) => {
        let { data } = err;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.IS_LOADING,
          payload: false,
        });
      }
    );
  };
};

export const editUserMenu = (values: any, setSubmitting: any) => {
  return (dispatch: Function, getState: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, 'PLEASE CHECK YOUR INTERNET');
      dispatch({
        type: Utils.ActionName.IS_LOADING,
        payload: false,
      });
      return;
    }
    const { firstName, lastName } = values;

    const dataToSend = {
      id: localStorage.getItem('id'),
      data: {
        firstName: firstName,
        lastName: lastName,
      },
    };
    Utils.api.patchApiCall(
      Utils.endPoints.updateUser,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (data.statusCode === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });

          localStorage.setItem('firstName', firstName);
          dispatch({
            type: Utils.ActionName.USER_MENU_EDIT,
            payload: {
              isUserEdit: false,
              firstName: firstName,
              lastName: lastName,
            },
          });

          setSubmitting(false);
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(2, data.message);
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });
        }
      },
      (err: any) => {
        let { data } = err;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.IS_LOADING,
          payload: false,
        });
      }
    );
  };
};

export const getUserMenuDetail = (id: any) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, 'PLEASE CHECK YOUR INTERNET CONNECTION');
      dispatch({
        type: Utils.ActionName.IS_LOADING,
        payload: false,
      });
      return;
    }

    const dataToSend = ``;
    let param = `?id=${id}`;
    Utils.api.getApiCall(
      `${Utils.endPoints.getAdminDetail}${param}`,
      dataToSend,
      (respData: any) => {
        let { data } = respData;

        if (data.statusCode === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });

          dispatch({
            type: Utils.ActionName.USER_MENU_EDIT,
            payload: {
              firstName: data.data[0].firstName,
              lastName: data.data[0].lastName,
              email: data.data[0].email,
            },
          });
        } else {
          Utils.showAlert(2, data.message);
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });
        }
      },

      (err: any) => {
        let { data } = err;
        Utils.showAlert(2, data.message);
        dispatch({
          type: Utils.ActionName.IS_LOADING,
          payload: false,
        });
      }
    );
  };
};
